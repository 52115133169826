@tailwind base;
@tailwind components;
@tailwind utilities;

#dashboard-nav {
    background-color: #FF3A20;

    button {
        color: white;
    }
}

:root {
--nav-height: 80px;
}

#stage-page nav {
    height: var(--nav-height);
}
#stage-page #main-content {
    min-height: calc(100vh - var(--nav-height) * 2);
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 64px;
    padding-top: calc(var(--nav-height) * 0.4);
    padding-bottom: 64px;
    padding-bottom: var(--nav-height);
}

#video-player {
    position: relative;
    width: 100%; /* Adjusts to the width of the parent container */
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0; /* Ensures the container's height is controlled by padding */
}
#video-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

/* For screens smaller than 768px (tablet size) */
@media (max-width: 768px) {
    #video-player  {
        width: 90%; /* Slightly smaller for tablets */
        padding-bottom: 56.25%; /* Maintain 16:9 aspect ratio */
    }
    #stage-page #main-content {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/* For screens smaller than 480px (mobile size) */
@media (max-width: 480px) {
    #video-player  {
        width: 100%; /* Full width on mobile */
        padding-bottom: 75%; /* Change to 4:3 aspect ratio for smaller screens */
    }
    #stage-page #main-content {
        padding-left: 10px;
        padding-right: 10px;
    }
}

/* For larger screens (desktops or larger) */
@media (min-width: 1200px) {
    #video-player  {
        width: 90%; /* Slightly smaller on large screens for better layout */
        padding-bottom: 56.25%; /* Maintain 16:9 aspect ratio */
    }
    #stage-page #main-content {
        padding-left: 10%;
        padding-right: 10%;
    }
}

#preview-mode {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(217, 255, 0, 0.6);
    text-align: center;
    padding: 2px;
    z-index: 1;
}